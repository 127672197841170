import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export interface DialogConfig<T = any> {
  data?: T;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  disableClose?: boolean;
}

export interface DialogRef<T = any> {
  afterClosed(): Observable<T>;
  close(result?: T): void;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogSubject = new BehaviorSubject<{
    component: any;
    config: DialogConfig;
    dialogRef: DialogRef;
  } | null>(null);

  dialog$ = this.dialogSubject.asObservable();

  open<T>(component: any, config: DialogConfig = {}): DialogRef<T> {
    // Create a new Subject for this specific dialog instance
    const currentDialogResult = new Subject<T>();

    const dialogRef: DialogRef<T> = {
      afterClosed: () => currentDialogResult.asObservable().pipe(take(1)),
      close: (result?: T) => {
        currentDialogResult.next(result as T);
        currentDialogResult.complete();
        this.dialogSubject.next(null);
      }
    };

    this.dialogSubject.next({
      component,
      config,
      dialogRef
    });

    return dialogRef;
  }

  close() {
    this.dialogSubject.next(null);
  }
}
